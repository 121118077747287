<script>
import { Link } from "@inertiajs/inertia-vue3";

export default {
    name: "MobileMenuItem",
    components: {
        Link,
    },
    data() {
        return {
            open: false,
        };
    },
    props: {
        model: Object,
    },
    computed: {
        isFolder: function () {
            let m = JSON.parse(JSON.stringify(this.model));
            return m.children?.length;
        },
    },
    methods: {
        toggle: function () {
            if (this.isFolder) {
                this.open = !this.open;
            }
        },
        changeType: function () {
            if (!this.isFolder) {
                Vue.set(this.model, "children", []);
                this.addChild();
                this.open = true;
            }
        },
        // addChild: function () {
        //     this.model.children.push({
        //         name: "New Item",
        //     });
        // },
        // isFolder: function () {
        //     console.log(this.model, this.model?.length, this.model.children?.length);
        //     return Object.keys(this.model).length > 0;
        // },
    },
};
</script>

<template>
    <li class="relative" :class="[isFolder ? 'folder' : 'file']">
        <Link :href="`/kategoriler/${model.label.toLowerCase().replace(/\s+/g, '-').replace(`ı`, 'i').replace(`ö`, 'o').replace(`ü`, 'ü').replace(`ğ`, 'g')}`">
            {{ model.label }}
        </Link>
        <label class="!border-b-0 !absolute right-2 top-2" :class="{ open: open }" @click="toggle" @dblclick="changeType">
            <!--            <span v-if="isFolder">{{ isFolder ? Object.keys(this.model).length : "" }}</span>-->
            <div class="mt-1 absolute right-0 top-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="8.81" height="15.318" viewBox="0 0 8.81 15.318">
                    <path id="Path_55" data-name="Path 55" d="M.337.337a1.151,1.151,0,0,1,1.627,0l5.7,5.7,5.7-5.7a1.15,1.15,0,1,1,1.627,1.627L8.473,8.473a1.151,1.151,0,0,1-1.627,0L.337,1.964A1.151,1.151,0,0,1,.337.337Z" transform="translate(0 15.318) rotate(-90)" fill="#231f20"></path>
                </svg>
            </div>
        </label>
        <ul v-show="open" v-if="isFolder" :class="{ open: open }">
            <MobileMenuItem v-for="(menuItemSub, index2) in model.children" :key="index2" :model="menuItemSub"></MobileMenuItem>
            <!--            <li :class="[isFolder(menuItemSub) ? 'folder' : 'file']" v-for="(menuItemSub, index2) in menuItem" :key="index2">-->
            <!--                <label :class="{ open: open }" @click="toggle(menuItemSub)" @dblclick="changeType">-->
            <!--                    {{ menuItemSub.label }}-->
            <!--                    &lt;!&ndash;                    <span v-if="isFolder">{{ isFolder ? Object.keys(menuItemSub).length : "" }}</span>&ndash;&gt;-->
            <!--                </label>-->
            <!--            </li>-->

            <!--            <li class="add" @click="addChild"><label>Add New Item</label></li>-->
        </ul>
    </li>
</template>

<style scoped>
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

ol,
ul {
    list-style: none;
    padding: 0px;
}

a {
    color: #a0f6aa;
    text-decoration: none;
}

h1 {
    text-align: center;
    width: 90%;
    margin: 2em auto 0;
    color: #507b55;
    font-weight: bold;
}

.cd-accordion-menu {
    width: 90%;
    max-width: 600px;
    margin: 4em auto;
    background: rgba(0, 0, 0, 0.8);
    border-bottom: 2px solid #ebebeb;
}

.cd-accordion-menu li {
    user-select: none;
}

.cd-accordion-menu li span {
    float: right;
}

.cd-accordion-menu label,
.cd-accordion-menu a {
    position: relative;
    display: block;
    padding: 12px 15px 12px 15px;
    border-bottom: 2px solid #ebebeb;
    color: black;
}

.no-touch .cd-accordion-menu label:hover,
.no-touch .cd-accordion-menu a:hover {
    background: #52565d;
}

.cd-accordion-menu label::before,
.cd-accordion-menu label::after,
.cd-accordion-menu a::after {
    /* icons */
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.cd-accordion-menu label {
    cursor: pointer;
}

.cd-accordion-menu label span {
    float: right;
    color: #828282;
}

/*.cd-accordion-menu li.file > label::before {*/
/*    content: "\f15b";*/
/*}*/

/*.cd-accordion-menu li.folder > label::after {
    content: '\f054';
    position: absolute;
    right: 0.5rem;
    top: 0.75rem;
    margin-top: 0.25rem;
}*/

/*
.cd-accordion-menu li.file > label::before {
    content: "\f15b";
}
.cd-accordion-menu li.add > label::before {
    content: "\f067";
}
*/

.cd-accordion-menu label::before {
    /* arrow icon */
    font: normal normal normal 14px/1 FontAwesome;
    left: 18px;
    transform: translateY(-50%);
    transition: transform 0.3s;
}

.cd-accordion-menu label.open::before {
    transform: translateY(-25%) rotate(90deg);
}

.cd-accordion-menu ul label,
.cd-accordion-menu ul a {
    /*background: #000;*/
    border-bottom: 2px solid #ebebeb;
    padding-left: 30px;
}

.no-touch .cd-accordion-menu ul label:hover,
.no-touch .cd-accordion-menu ul a:hover {
    background: #3c3f45;
}

.cd-accordion-menu > li:last-of-type > label,
.cd-accordion-menu > li:last-of-type > a,
.cd-accordion-menu > li > ul > li:last-of-type label,
.cd-accordion-menu > li > ul > li:last-of-type a {
    box-shadow: none;
}

.cd-accordion-menu ul label::before {
    left: 36px;
}

.cd-accordion-menu ul label::after,
.cd-accordion-menu ul a::after {
    left: 59px;
}

.cd-accordion-menu ul ul label,
.cd-accordion-menu ul ul a {
    padding-left: 100px;
}

.cd-accordion-menu ul ul label::before {
    left: 54px;
}

.cd-accordion-menu ul ul label::after,
.cd-accordion-menu ul ul a::after {
    left: 77px;
}

.cd-accordion-menu ul ul ul label,
.cd-accordion-menu ul ul ul a {
    padding-left: 118px;
}

.cd-accordion-menu ul ul ul label::before {
    left: 72px;
}

.cd-accordion-menu ul ul ul label::after,
.cd-accordion-menu ul ul ul a::after {
    left: 95px;
}
</style>
